.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
  width: 100%;
  z-index: 10;
  height: 50px;
}
@media (min-width: 960.01px) {
  .header {
    height: 80px;
  }
}
.header .contents {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  height: 100%;
  justify-content: space-between;
  margin: auto;
  max-width: 1200px;
  padding: 0 4px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .header .contents {
    padding: 0 8px;
  }
}
@media (min-width: 960.01px) {
  .header .contents {
    padding: 0 12px;
  }
}
.header .contents .title {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
.header .contents .title .logoLink {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header .contents .title .logoLink .logo {
  height: 46px;
  margin-right: 8px;
  width: 46px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .header .contents .title .logoLink .logo {
    margin-right: 12px;
  }
}
@media (min-width: 960.01px) {
  .header .contents .title .logoLink .logo {
    height: 70px;
    margin-right: 20px;
    width: 70px;
  }
}
.header .contents .title h1 {
  font-weight: normal;
  margin: 0;
  font-size: 1rem;
  margin-right: 4px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .header .contents .title h1 {
    margin-right: 8px;
  }
}
@media (min-width: 960.01px) {
  .header .contents .title h1 {
    font-size: 1.2rem;
    margin-right: 12px;
  }
}
.header .contents .menu {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}
.header {
  transition: background-color 400ms, color 400ms;
}
.lightTheme .header {
  background-color: #222;
  color: #fff;
}
.darkTheme .header {
  background-color: #000;
  color: #fff;
}
