.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.page {
  align-self: stretch;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}
.page .pageFader {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.page .pageFader > div {
  padding: 15px 8px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .page .pageFader > div {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .page .pageFader > div {
    padding: 28px 24px;
  }
}
.page .pageFader > div.pageFade-appear {
  opacity: 0.01;
}
.page .pageFader > div.pageFade-appear.pageFade-appear-active {
  opacity: 1;
  transition: opacity 0.2s;
}
.page .pageFader > div.pageFade-enter {
  opacity: 0.01;
}
.page .pageFader > div.pageFade-enter.pageFade-enter-active {
  opacity: 1;
  transition: opacity 0.2s;
}
.page .pageFader > div.pageFade-leave {
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
}
.page .pageFader > div.pageFade-leave.pageFade-leave-active {
  opacity: 0.01;
  transition: opacity 0.2s;
}
