.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.app {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  transition: background-color 400ms, color 400ms;
}
.app.lightTheme {
  background: #eee;
  color: #111;
}
.app.lightTheme a {
  color: #13a;
}
.app.darkTheme {
  background: #222;
  color: #eee;
}
.app.darkTheme a {
  color: #9ae;
}
