.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
* {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}
html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.42857143;
  font-size: 13px;
}
@media (min-width: 960.01px) {
  html {
    font-size: 16px;
  }
}
a {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: underline;
}
a,
a:active,
a:focus {
  outline: none;
}
strong {
  font-weight: bold;
}
h1 {
  font-weight: bold;
  margin: 32px 0 24px 0;
  font-size: 1.9rem;
}
@media (min-width: 480.01px) {
  h1 {
    font-size: 1.9rem;
  }
}
h2 {
  font-weight: bold;
  margin: 24px 0 16px 0;
  font-size: 1.7rem;
}
@media (min-width: 480.01px) {
  h2 {
    font-size: 1.7rem;
  }
}
h3 {
  font-weight: bold;
  margin: 16px 0 10px 0;
  font-size: 1.4rem;
}
@media (min-width: 480.01px) {
  h3 {
    font-size: 1.4rem;
  }
}
h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 16px 0 10px;
  padding: 2px 0;
}
.lightTheme h4 {
  border-bottom: 1px dashed #13a;
  color: #13a;
}
.darkTheme h4 {
  border-bottom: 1px dashed #9ae;
  color: #9ae;
}
p {
  line-height: 1.42857143;
  margin: 6px 0 10px;
  text-align: justify;
}
ul {
  list-style-type: square;
  list-style-position: outside;
  margin: 6px 0 6px 24px;
}
ul li {
  line-height: 1.42857143;
  margin: 6px 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
p:first-child {
  margin-top: 0;
}
.lightTheme .success {
  color: #262;
}
.lightTheme .error {
  color: #922;
}
.darkTheme .success {
  color: #484;
}
.darkTheme .error {
  color: #a33;
}
