.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.slider {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex-grow: 1;
  height: 1.75rem;
  min-width: 6rem;
  position: relative;
}
.slider .track {
  background: #0a1e64;
  border-radius: 0.125rem;
  height: 0.25rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 1.2rem);
  z-index: 1;
}
.slider .clickArea {
  border-radius: 0.6rem;
  cursor: pointer;
  height: 1.2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
}
.slider .slideButton {
  background: #13a;
  border-radius: 0.6rem;
  cursor: pointer;
  height: 1.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  z-index: 3;
}
.slider:hover .track,
.slider.dragging .track {
  background: #0b216e;
}
.slider:hover .slideButton,
.slider.dragging .slideButton {
  background: #1641d8;
}
