.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.figure {
  border-radius: 8px;
  margin: 16px 0px;
  padding: 12px;
  position: relative;
}
@media (min-width: 480.01px) {
  .figure.twoColumn .subFigures .subFigure {
    width: calc(50% - 6px);
  }
}
@media (max-width: 480px) {
  .figure.twoColumn .subFigures .subFigure:not(:last-child) {
    margin-bottom: 12px;
  }
}
.figure.extraControlBarSpace .controlBar {
  margin-top: 20px;
}
.figure .figureCounter {
  font-size: 0.8em;
  position: absolute;
  right: 7px;
  top: 4px;
  user-select: none;
  z-index: 1;
}
.figure .subFigures {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.figure .subFigures .subFigure {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  max-width: 800px;
  position: relative;
  width: 100%;
}
.figure .subFigures .subFigure.extraMargin {
  padding: 0 0.5rem 0.5rem;
}
.figure .subFigures .subFigure:only-child {
  margin: auto;
}
.figure .subFigures .subFigure.noAxes svg .axis {
  display: none;
}
.figure .subFigures .subFigure.noNumbers svg .tick text {
  display: none;
}
.figure .subFigures .subFigure.pointer svg,
.figure .subFigures .subFigure.pointer canvas {
  cursor: pointer;
}
.figure .subFigures .subFigure .title {
  font-size: 0.9em;
  margin: 2px auto 14px;
}
.figure .subFigures .subFigure .subFigureInner {
  position: relative;
  width: 100%;
}
.figure .subFigures .subFigure .subFigureInner svg {
  display: block;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: visible;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.figure .subFigures .subFigure .subFigureInner svg .tick {
  stroke: currentColor;
}
.figure .subFigures .subFigure .subFigureInner svg .tick line {
  stroke: currentColor;
}
.figure .subFigures .subFigure .subFigureInner svg .domain {
  stroke: currentColor;
}
.figure .subFigures .subFigure .subFigureInner canvas {
  left: 0;
  height: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.figure .controlBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 8px auto 0;
  max-width: 800px;
}
.figure .controlBar > div {
  margin-left: 0.75em;
}
.figure .controlBar > div:first-child {
  margin-left: 0;
}
.figure .controlBar .item {
  background: #13a;
  border-radius: 0.375em;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9em;
  font-weight: bold;
  height: 1.75em;
  justify-content: center;
  padding: 0.25em 0.5em;
}
.figure .controlBar .btn {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.figure .controlBar .btn:hover {
  background: #1641d8;
}
.figure .controlBar .btn.icon {
  padding: 0.25em;
}
.figure .controlBar .btn .icon {
  height: 1.4em;
  width: 1.4em;
}
.figure .controlBar .btn .icon svg {
  fill: currentColor;
}
.figure.twoColumn .controlBar {
  max-width: none;
}
.lightTheme .figure {
  background: #222;
  color: #fff;
}
.lightTheme .figure .figureCounter {
  color: #888;
}
.darkTheme .figure {
  background: #070707;
  color: #fff;
}
.darkTheme .figure .figureCounter {
  color: #888;
}
