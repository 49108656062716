.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.treeContainer {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  height: 100%;
  overflow: visible;
  position: relative;
  user-select: none;
  width: 100%;
}
.treeContainer .treeInnerContainer {
  flex-grow: 1;
}
.treeContainer .treeInnerContainer .tree {
  cursor: pointer;
  position: absolute;
}
.treeContainer .treeInnerContainer .tree .chapter {
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  overflow: hidden;
  position: absolute;
  transition: height 0.4s;
  width: 320px;
  z-index: 0;
}
.treeContainer .treeInnerContainer .tree .chapter.previousActive {
  z-index: 1;
}
.treeContainer .treeInnerContainer .tree .chapter.active {
  z-index: 2;
}
.treeContainer .treeInnerContainer .tree .chapter.active .title {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}
.treeContainer .treeInnerContainer .tree .chapter.active .title svg {
  transform: translateX(-50%) scaleY(-1);
}
.treeContainer .treeInnerContainer .tree .chapter .title {
  background: inherit;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-flow: column nowrap;
  height: 80px;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.treeContainer .treeInnerContainer .tree .chapter .title p {
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  transition: margin-bottom 0.4s 0.15s;
}
.treeContainer .treeInnerContainer .tree .chapter .title svg {
  bottom: 8px;
  height: 8px;
  margin: auto;
  left: 50%;
  opacity: 0;
  overflow: visible;
  position: absolute;
  transform: translateX(-50%) scaleY(1);
  transition: opacity 0.4s, transform 0.4s;
  width: 12px;
}
.treeContainer .treeInnerContainer .tree .chapter .title svg path {
  stroke-width: 1px;
}
.treeContainer .treeInnerContainer .tree .chapter .title:hover p {
  margin-bottom: 8px;
  transition: margin-bottom 0.4s;
}
.treeContainer .treeInnerContainer .tree .chapter .title:hover svg {
  opacity: 1;
  transition: opacity 0.4s 0.15s, transform 0.4s;
}
.treeContainer .treeInnerContainer .tree .chapter .description {
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9rem;
  justify-content: flex-end;
  padding: 12px 12px 9px;
  position: relative;
  text-align: justify;
  transition: top 0.4s;
  z-index: 1;
}
.treeContainer .treeInnerContainer .tree .chapter .description p {
  line-height: 1.15;
  margin: 0 0 6px 0;
}
.treeContainer .treeInnerContainer .tree .chapter .description p.study {
  font-weight: bold;
  margin: 8px 0 0 0;
  opacity: 0.5;
  text-align: right;
  transition: opacity 0.4s 0.15s, margin-right 0.4s 0.15s;
}
.treeContainer .treeInnerContainer .tree .chapter .description svg {
  height: 12px;
  opacity: 0;
  overflow: visible;
  position: absolute;
  right: 14px;
  bottom: 2.5px;
  transform: translateY(-50%);
  transition: opacity 0.4s;
  width: 8px;
}
.treeContainer .treeInnerContainer .tree .chapter .description svg path {
  stroke-width: 1px;
}
.treeContainer .treeInnerContainer .tree .chapter .description:hover p.study {
  margin-right: 20px;
  opacity: 1;
  transition: opacity 0.4s, margin-right 0.4s;
}
.treeContainer .treeInnerContainer .tree .chapter .description:hover svg {
  opacity: 1;
  transition: opacity 0.4s 0.15s;
}
.treeContainer svg.treeBackground {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  position: absolute;
  z-index: -1;
}
.treeContainer svg.treeBackground .treeLine {
  fill: none;
  stroke-width: 5px;
}
.treeContainer.dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.treeContainer.dragging svg.treeBackground {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.treeContainer.dragging.invalidClick .tree {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
@media (min-width: 960.01px) {
  .treeContainer svg.treeBackground .treeLine {
    filter: url(#shadow);
  }
}
.lightTheme .tree {
  color: #fff;
}
.lightTheme .tree .chapter {
  background: #013;
}
.lightTheme .tree .chapter.unwritten {
  color: #777;
}
.lightTheme .tree .chapter .title svg path {
  fill: #fff;
  stroke: #fff;
}
.lightTheme .tree .chapter .description {
  background: #125;
}
.lightTheme .tree .chapter .description p.study {
  color: #fff;
}
.lightTheme .tree .chapter .description svg path {
  fill: #fff;
  stroke: #fff;
}
.lightTheme svg .treeLine {
  stroke: #125;
}
.darkTheme .tree {
  color: #000;
}
.darkTheme .tree .chapter {
  background: #adf;
}
.darkTheme .tree .chapter.unwritten {
  color: #999;
}
.darkTheme .tree .chapter .title svg path {
  fill: #000;
  stroke: #000;
}
.darkTheme .tree .chapter .description {
  background: #def;
}
.darkTheme .tree .chapter .description p.study {
  color: #000;
}
.darkTheme .tree .chapter .description svg path {
  fill: #000;
  stroke: #000;
}
.darkTheme svg .treeLine {
  stroke: #adf;
}
