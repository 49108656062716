.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.spinner {
  height: 100px;
  margin: auto;
  width: 100px;
  position: relative;
}
.spinner div {
  animation: spinner linear 0.8s infinite;
  border-radius: 100%;
  height: 24px;
  left: 46px;
  position: absolute;
  top: 0px;
  transform-origin: 4px 50px;
  width: 8px;
}
.spinner div:nth-child(16) {
  animation-delay: 0s;
  transform: rotate(360deg);
}
.spinner div:nth-child(15) {
  animation-delay: -0.05s;
  transform: rotate(337.5deg);
}
.spinner div:nth-child(14) {
  animation-delay: -0.1s;
  transform: rotate(315deg);
}
.spinner div:nth-child(13) {
  animation-delay: -0.15s;
  transform: rotate(292.5deg);
}
.spinner div:nth-child(12) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}
.spinner div:nth-child(11) {
  animation-delay: -0.25s;
  transform: rotate(247.5deg);
}
.spinner div:nth-child(10) {
  animation-delay: -0.3s;
  transform: rotate(225deg);
}
.spinner div:nth-child(9) {
  animation-delay: -0.35s;
  transform: rotate(202.5deg);
}
.spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(180deg);
}
.spinner div:nth-child(7) {
  animation-delay: -0.45s;
  transform: rotate(157.5deg);
}
.spinner div:nth-child(6) {
  animation-delay: -0.5s;
  transform: rotate(135deg);
}
.spinner div:nth-child(5) {
  animation-delay: -0.55s;
  transform: rotate(112.5deg);
}
.spinner div:nth-child(4) {
  animation-delay: -0.6s;
  transform: rotate(90deg);
}
.spinner div:nth-child(3) {
  animation-delay: -0.65s;
  transform: rotate(67.5deg);
}
.spinner div:nth-child(2) {
  animation-delay: -0.7s;
  transform: rotate(45deg);
}
.spinner div:nth-child(1) {
  animation-delay: -0.75s;
  transform: rotate(22.5deg);
}
.lightTheme .spinner div {
  background: #13a;
}
.darkTheme .spinner div {
  background: #9ae;
}
@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
