.gpPlot .means path.mean {
  fill: none;
  stroke: #13f;
  stroke-width: 2;
}
.gpPlot .samples path.sample {
  fill: none;
  stroke-width: 1;
}
.gpPlot .samples path.sample:nth-child(12n+1) {
  stroke: #d22;
}
.gpPlot .samples path.sample:nth-child(12n+2) {
  stroke: #dd2;
}
.gpPlot .samples path.sample:nth-child(12n+3) {
  stroke: #2dd;
}
.gpPlot .samples path.sample:nth-child(12n+4) {
  stroke: #d2d;
}
.gpPlot .samples path.sample:nth-child(12n+5) {
  stroke: #2d2;
}
.gpPlot .samples path.sample:nth-child(12n+6) {
  stroke: #ddd;
}
.gpPlot .samples path.sample:nth-child(12n+7) {
  stroke: #d82;
}
.gpPlot .samples path.sample:nth-child(12n+8) {
  stroke: #2d8;
}
.gpPlot .samples path.sample:nth-child(12n+9) {
  stroke: #82d;
}
.gpPlot .samples path.sample:nth-child(12n+10) {
  stroke: #8d2;
}
.gpPlot .samples path.sample:nth-child(12n+11) {
  stroke: #28d;
}
.gpPlot .samples path.sample:nth-child(12n+0) {
  stroke: #d28;
}
.gpPlot .measurements circle.measurement {
  fill: #e53;
}
