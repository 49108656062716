.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.explainer {
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -100%);
  z-index: 120;
}
.explainer .arrow {
  border-left: 0.577rem solid transparent;
  border-right: 0.577rem solid transparent;
  border-top: 1rem solid #f00;
  height: 0;
  width: 0;
}
.explainer .contentsContainer {
  bottom: 1rem;
  position: absolute;
}
.explainer .contentsContainer .contents {
  border-radius: 0.75rem;
  bottom: 0;
  left: -0.75rem;
  padding: 0.5rem 0.75rem;
  position: absolute;
  text-align: center;
  width: calc(100% + 2*0.75rem + 1.154rem);
}
.lightTheme .explainer .arrow {
  border-top-color: #0a1e64;
}
.lightTheme .explainer .contentsContainer .contents {
  background: #0a1e64;
  color: #fff;
}
.darkTheme .explainer .arrow {
  border-top-color: #08174d;
}
.darkTheme .explainer .contentsContainer .contents {
  background: #08174d;
  color: #fff;
}
