.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.app header nav a.menuItem {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.5;
  text-decoration: none;
  width: 40px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .app header nav a.menuItem {
    width: 50px;
  }
}
@media (min-width: 960.01px) {
  .app header nav a.menuItem {
    width: 80px;
  }
}
.app header nav a.menuItem.active {
  cursor: auto;
}
.app header nav a.menuItem:hover,
.app header nav a.menuItem.active {
  opacity: 1;
}
.app header nav a.menuItem .icon {
  height: 30px;
  width: 30px;
}
@media (min-width: 960.01px) {
  .app header nav a.menuItem .icon {
    height: 36px;
    width: 36px;
  }
}
.app header nav a.menuItem .icon svg {
  fill: currentColor;
}
.app header nav a.menuItem .label {
  display: none;
  font-size: 0.8rem;
  margin-top: 7px;
}
@media (min-width: 960.01px) {
  .app header nav a.menuItem .label {
    display: block;
  }
}
