.pagePadding {
  padding: 15px 8px;
}
@media (min-width: 480.01px) and (max-width: 960px) {
  .pagePadding {
    padding: 20px 16px;
  }
}
@media (min-width: 960.01px) {
  .pagePadding {
    padding: 28px 24px;
  }
}
.notSelectable {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.counter {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.counter .count {
  cursor: default;
  margin: 0 0.35rem;
  min-width: 2.2rem;
  user-select: none;
  text-align: center;
}
.counter .minus,
.counter .plus {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: transparent;
  border-bottom: 0.6em solid transparent;
  border-top: 0.6em solid transparent;
  cursor: pointer;
  height: 0px;
  transform: scaleX(1.25);
  width: 0px;
}
.counter .minus {
  border-right: 0.6em solid #13a;
}
.counter .minus:hover {
  border-right-color: #1641d8;
}
.counter .plus {
  border-left: 0.6em solid #13a;
}
.counter .plus:hover {
  border-left-color: #1641d8;
}
